/**
 * Table
 * ================================================== */
.cmn-table {
  table, thead, tbody, tr, th, td {
    @media screen and (max-width: $medium) {
      display: block;
      border-bottom: none;
    }
  }
  tr {
    &:not(:last-of-type) {
      td {
        border-bottom: none;
      }
    }
  }
  th, td {
    font-size: 20px;
    padding: 24px;
    @media screen and (max-width: $medium) {
      font-size: 16px;
      padding: 16px;
    }
  }
  th {
    background-color: $background-color01;
    border: 1px solid $border-color02;
    font-weight: 700;
    @media screen and (max-width: $medium) {
      border-bottom: none;
    }
  }
  td {
    border: 1px solid $border-color02;
  }
}