/**
 * Setting
 * ================================================== */
// key colors
$key-color01: #ef6e29;

// border colors
$border-color01: #EEEEEE;
$border-color02: #CCCCCC;
$border-color03: #444444;

// background colors
$background-color01: #EEEEEE;
$background-color02: #444444;
$background-color03: #333333;
$background-color04: #CCCCCC;

// font colors
$font-color01: #444444;

// base width
$base-width01: 1260px;

// media querys
$medium: 768px;