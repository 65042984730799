/**
 * Fold
 * ================================================== */
.fold-area {
  .fold-inner {
    overflow: hidden;
  }
  .fold-title {
    background-color: $background-color03;
    color: #fff;
    font-size: 24px;
    padding: 24px;
    line-height: 1;
    font-weight: 700;
    cursor: pointer;
    position: relative;
    @media screen and (max-width: $medium) {
      font-size: 16px;
      padding: 20px 16px;
    }
    &::before,
    &::after {
      content: '';
      display: block;
      width: 1em;
      height: 1px;
      background-color: #fff;
      position: absolute;
      top: 50%;
      right: 36px;
      transition: all .5s;
    }
    &::after {
      transform: rotate(-90deg);
    }
    &.opend {
      &::after {
        transform: rotate(0deg);
      }
    }
  }
  .fold-info {
    margin-top: 44px;
    display: none;
    @media screen and (max-width: $medium) {
      margin-top: 22px;
    }
    .detail-list {
      .list-item {
        display: flex;
        align-items: flex-start;
        justify-content: flex-start;
        margin: -22px;
        @media screen and (max-width: $medium) {
          flex-direction: column;
          margin: -11px;
        }
        &:nth-of-type(n+2) {
          margin-top: 22px;
        }
        .detail-img-area,
        .detail-text-area {
          margin: 22px;
          @media screen and (max-width: $medium) {
            margin: 11px;
          }
        }
        .detail-img-area {
          flex: none;
          @media screen and (max-width: $medium) {
            width: 100%;
          }
          .detail-img {
            display: block;
            width: 552px;
            height: 463px;
            object-fit: cover;
            @media screen and (max-width: $medium) {
              width: 100%;
              height: 230px;
            }
          }
        }
        .detail-text-area {
          .detail-title {
            font-size: 24px;
            margin-bottom: 16px;
            @media screen and (max-width: $medium) {
              margin-bottom: 8px;
            }
          }
          .detail-text {
            font-size: 16px;
            line-height: 1.8;
          }
        }
      }
    }
  }
}