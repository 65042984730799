/**
 * Cart list
 * ================================================== */
.cart-list {
  #contents {
    h4,h3 {
      &[id]{
        padding-top: 126px;
        margin-top: -126px;
        @media screen and (max-width: $medium) {
          padding-top: 74px;
          margin-top: -74px;
        }
      }
      &.section-title-third {
        &::before {
          top: calc(126px + 0.75em);
          @media screen and (max-width: $medium) {
            top: calc(74px + 0.75em);
          }
        }
      }
    }
  }
  &.tax-business_list {
    #contents {
      h3 {
        text-align: center;
        margin-bottom: 64px;
        color: $font-color01;
        font-size: 32px;
        font-weight: 700;
        position: relative;
        z-index: -1;
        @media screen and (max-width: $medium) {
          font-size: 26px;
          margin-bottom: 40px;
        }
      }
    }
  }
  .cart-list-area {
    margin-top: 120px;
    max-width: $base-width01;
    padding: 0 20px;
    margin: 0 auto;
    + .cart-list-area {
      margin-top: 104px;
    }
  }
  .cart-list-wrapper {
    &:not(:last-of-type) {
      margin-bottom: 96px;
      @media screen and (max-width: $medium) {
        margin-bottom: 56px;
      }
    }
    .cart-list {
      display: flex;
      justify-content: center;
      flex-wrap: wrap;
      margin: -18px -14px;
      &::before,
      &::after {
        content: '';
        display: block;
        width: 388px;
        margin: 0 14px;
        height: 0;
        order: 1;
      }
      .list-item {
        width: 388px;
        max-width: 100%;
        margin: 18px 14px;
        &.tag-in-stock,
        &.tag-out-of-stock {
          .list-anc {
            position: relative;
            &::before {
              display: block;
              position: absolute;
              top: 11px;
              right: 14px;
              background-color: #fff;
              border-radius: 9999px;
              z-index: 1;
              padding: 4px 12px;
              font-size: 12px;
            }
          }
        }
        &.tag-in-stock {
          .list-anc {
            &::before {
              content: '在庫あり';
              border: 1px solid $key-color01;
              color: $key-color01;
            }
          }
        }
        &.tag-out-of-stock {
          .list-anc {
            &::before {
              content: '在庫なし';
              border: 1px solid $border-color03;
              color: $font-color01;
            }
          }
        }
        .list-anc {
          display: block;
          text-decoration: none;
          &:hover {
            .thumbnail {
              .thum-capt {
                background-color: rgba($color: $key-color01, $alpha: .7);
              }
            }
          }
          .thumbnail {
            display: block;
            position: relative;
            .thum-img {
              vertical-align: middle;
              width: 388px;
              height: 291px;
              max-width: 100%;
              //object-fit: cover;
              object-fit: contain;
              background-color: #333;
            }
            .thum-capt {
              position: absolute;
              bottom: 0;
              left: 0;
              width: 100%;
              background-color: rgba($color: #000, $alpha: .5);
              padding: 16px 24px;
              color: white;
              font-size: 22px;
              font-weight: 700;
              transition: all .2s;
            }
          }
          .info-area {
            background-color: $background-color01;
            padding: 16px;
            @media screen and (max-width: $medium) {
              padding: 16px;
            }
            .info-set-wrapper {
              display: flex;
              align-items: flex-start;
              justify-content: flex-start;
              flex-wrap: wrap;
              margin: -4px;
              .info-set {
                display: flex;
                align-items: center;
                justify-content: flex-start;
                margin: 4px;
                .info-tag {
                  background-color: $background-color02;
                  padding: 6px 12px 8px;
                  line-height: 1;
                  border-radius: 9999px;
                  color: white;
                  margin-right: 8px;
                  font-size: 12px;
                  width: 50px;
                  text-align: center;
                  /* @media screen and (max-width: $medium) {
                    font-size: 12px;
                  } */
                }
                .info-text {
                  font-size: 15px;
                  color: $font-color01;
                  font-weight: 400;
                  line-height: 1.25;
                  /* @media screen and (max-width: $medium) {
                    font-size: 16px;
                  } */
                }
              }
            }
          }
        }
      }
    }
  }
  footer {
    margin-top: 180px;
    @media screen and (max-width: $medium) {
      margin-top: 128px;
    }
  }
}