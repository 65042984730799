/**
 * Cart detail
 * ================================================== */
.cart-detail {
  #contents {
    .contents-box {
      > section {
        margin-top: 80px;
        margin-bottom: 80px;
        @media screen and (max-width: $medium) {
          margin-top: 40px;
          margin-bottom: 40px;
        }
        &:last-of-type {
          margin-bottom: 0;
        }
      }
      nav.boxf_nav {
        .nav-content {
          .links-area {
            ul {
              li {
                &:nth-of-type(n) {
                  a {
                    &::before {
                      transform: translateY(-50%) rotate(-90deg);
                      transform-origin: 50% 50%;
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
    #detail-info {
      padding-top: 103px;
      margin-top: calc(-103px + 80px);
      @media screen and (max-width: $medium) {
        padding-top: 90px;
        margin-top: calc(-90px + 40px);
      }
    }
    .sec-cart-info {
      &.tag-in-stock,
      &.tag-out-of-stock {
        .first-section {
          position: relative;
          &::before {
            display: block;
            position: absolute;
            top: 0px;
            right: 0px;
            background-color: #fff;
            border-radius: 9999px;
            z-index: 1;
            padding: 4px 12px;
            font-size: 12px;
          }
        }
      }
      &.tag-in-stock {
        .first-section {
          &::before {
            content: '在庫あり';
            border: 1px solid $key-color01;
            color: $key-color01;
          }
        }
      }
      &.tag-out-of-stock {
        .first-section {
          &::before {
            content: '在庫なし';
            border: 1px solid $border-color03;
            color: $font-color01;
          }
        }
      }
      .cart-info-area {
        display: grid;
        grid-template-columns: 1fr 395px;
        grid-template-rows: auto 1fr;
        grid-auto-rows: minmax(10px, auto);
        @media screen and (max-width: $medium) {
          display: block;
        }
        .first-section {
          grid-column-start: 2;
          grid-column-end: 3;
          grid-row-start: 1;
          grid-row-end: 2;
          margin-bottom: 24px;
          @media screen and (max-width: $medium) {
            margin-bottom: 8px;
          }
          .title-area {
            .tag {
              display: inline-block;
              padding: 5px 14px;
              background-color: $background-color02;
              color: #fff;
              @media screen and (max-width: $medium) {
                font-size: 12px;
                padding: 4px 12px;
                margin-bottom: 4px;
              }
            }
            .title-text {
              display: block;
              font-size: 32px;
              font-weight: 700;
              color: $font-color01;
              @media screen and (max-width: $medium) {
                font-size: 22px;
                line-height: 1.4;
              }
            }
          }
        }
        .second-section {
          grid-column-start: 1;
          grid-column-end: 2;
          grid-row-start: 1;
          grid-row-end: 3;
          @media screen and (max-width: $medium) {
            margin-bottom: 16px;
          }
          .slide-area {
            width: calc(96.825396825396825vw - 448px);
            max-width: 772px;
            @media screen and (max-width: $medium) {
              width: 100%;
              max-width: 100%;
            }
            .slider {
              aspect-ratio: 772 / 579;
              margin-bottom: 8px;
              img {
                width: 100%;
                height: 100%;
                object-fit: contain;
                background-color: #333;
                display: block;
                user-select: none;
              }
            }
            .slider-thumbnail {
              .swiper-wrapper {
                flex-wrap: wrap;
                transform: none !important;
                margin: -4px;
                width: auto;
              }
              .swiper-slide {
                aspect-ratio: 122 / 97;
                width: calc(100% / 6 - 8px) !important;
                margin: 4px;
                cursor: pointer;
                flex-shrink: 1;
                img {
                  display: block;
                  width: 100%;
                  height: 100%;
                  object-fit: cover;
                }
              }
              .swiper-slide-thumb-active {
                border: 2px solid $key-color01;
                box-sizing: border-box;
              }
            }
          }
        }
        .third-section {
          grid-column-start: 2;
          grid-column-end: 3;
          grid-row-start: 2;
          grid-row-end: 3;
          margin-bottom: auto;
          .info-area {
            background-color: $background-color01;
            padding: 16px 12px;
            margin-bottom: 24px;
            @media screen and (max-width: $medium) {
              padding: 16px;
              margin-bottom: 16px;
            }
            .info-set-wrapper {
              display: flex;
              align-items: flex-start;
              justify-content: flex-start;
              flex-wrap: wrap;
              flex-direction: column;
              margin: -6px;
              .info-set {
                display: flex;
                align-items: center;
                justify-content: flex-start;
                margin: 6px;
                .large {
                  font-size: 150%;
                  font-weight: bold;
                }
                .info-tag {
                  background-color: $background-color02;
                  padding: 6px 12px 8px;
                  line-height: 1;
                  border-radius: 9999px;
                  color: white;
                  margin-right: 8px;
                  font-size: 14px;
                  width: 64px;
                  text-align: center;
                  @media screen and (max-width: $medium) {
                    font-size: 12px;
                  }
                }
                .info-text {
                  font-size: 16px;
                  color: $font-color01;
                  font-weight: 400;
                  line-height: 1.25;
                  @media screen and (max-width: $medium) {
                    font-size: 20px;
                  }
                }
              }
            }
          }
          .cart-info-text {
            line-height: 2;
            margin-bottom: 40px;
            @media screen and (max-width: $medium) {
              margin-bottom: 24px;
            }
          }
          .button-area {
            margin-bottom: 24px;
            .cmn-btn01 {
              font-size: 20px;
              width: 100%;
            }
          }
          .info-contact {
            display: flex;
            align-items: center;
            justify-content: center;
            border: 2px solid $border-color03;
            padding: 16px;
            .icon-area {
              margin-right: 16px;
              .contact-icon {
                display: block;
                width: 39px;
              }
            }
            .text-area {
              display: flex;
              flex-direction: column;
              .time {
                font-size: 16px;
                line-height: 1.3;
                margin-bottom: 4px;
              }
              .tel {
                font-size: 30px;
                text-decoration: none;
                color: $font-color01;
                font-weight: 700;
                line-height: 1;
              }
            }
          }
        }
      }
    }
    .sec-comment {
      .comment-title {
        font-size: 32px;
        font-weight: 700;
        margin-bottom: 24px;
        text-align: left;
        position: relative;
        padding-left: 36px;
        padding-right: 0;
        line-height: 1.6;
        @media screen and (max-width: $medium) {
          font-size: 22px;
          padding-left: calc(1em + 8px);
          margin-bottom: 8px;
        }
        &::before {
          content: '';
          display: block;
          width: 24px;
          height: 26px;
          position: absolute;
          top: .88em;
          left: 0;
          transform: translateY(-50%);
          background-image: url('../images/common/cmn_icn17.svg');
          background-position: 50% 50%;
          background-repeat: no-repeat;
          background-size: contain;
          @media screen and (max-width: $medium) {
            width: 1em;
            height: 1em;
          }
        }
      }
      .comment-text {
        font-size: 16px;
        line-height: 2;
      }
    }
    .sec-spec {
      .cmn-table {
        th {
          width: 282px;
          @media screen and (max-width: $medium) {
            width: 100%;
          }
        }
      }
    }
    .sec-contact {
      margin-top: 80px;
      border: 1px solid $key-color01;
      padding: 56px 80px;
      @media screen and (max-width: $medium) {
        padding: 40px 24px;
      }
      .contact-area {
        display: flex;
        align-items: center;
        justify-content: center;
        flex-wrap: wrap;
        margin: -22px -44px;
        @media screen and (max-width: $medium) {
          margin: -11px;
        }
        .contact-left,
        .contact-right {
          margin: 22px 44px;
          @media screen and (max-width: $medium) {
            margin: 11px;
          }
        }
        .contact-left {
          display: flex;
          align-items: center;
          justify-content: center;
          .icon-area {
            margin-right: 16px;
            .contact-icon {
              display: block;
              width: 39px;
            }
          }
          .text-area {
            display: flex;
            flex-direction: column;
            .time {
              font-size: 16px;
              line-height: 1.3;
              margin-bottom: 4px;
            }
            .tel {
              font-size: 30px;
              text-decoration: none;
              color: $font-color01;
              font-weight: 700;
              line-height: 1;
            }
          }
        }
        .contact-right {
          @media screen and (max-width: $medium) {
            width: 100%;
          }
          .cmn-btn01 {
            width: 621px;
            @media screen and (max-width: $medium) {
              width: 100%;
            }
          }
        }
      }
    }
    .sec-related-products {
      margin-top: 96px;
      font-size: 16px;
    }
  }
}