/**
 * Button
 * ================================================== */
#contents {
  .cmn-btn01 {
    display: block;
    border: 1px solid $key-color01;
    background-color: $key-color01;
    color: #fff;
    text-decoration: none;
    padding: 16px;
    text-align: center;
    font-weight: 700;
    transition: all .2s;
    cursor: pointer;
    &:hover {
      color: $key-color01;
      background-color: #fff;
    }
    &.large {
      font-size: 30px;
      @media screen and (max-width: $medium) {
        font-size: 24px;
      }
    }
    &.gray {
      background-color: $background-color04;
      border: 1px solid $border-color02;
      &:hover {
        color: $background-color04;
        background-color: #fff;
      }
    }
  }
}