/**
 * Title
 * ================================================== */
.section-title {
  color: $font-color01;
  margin-bottom: 50px;
  span {
    &:nth-of-type(1) {
      @media screen and (max-width: $medium) {
        padding: 0 20px;
      }
    }
  }
}
.section-title-second {
  font-size: 32px;
  font-weight: 700;
  position: relative;
  padding-bottom: 18px;
  text-align: center;
  margin-bottom: 64px;
  color: $font-color01;
  @media screen and (max-width: $medium) {
    font-size: 26px;
    margin-bottom: 40px;
  }
  &::before {
    content: '';
    display: block;
    width: 50px;
    height: 8px;
    background-color: $key-color01;
    position: absolute;
    bottom: 0;
    left: 50%;
    transform: translateX(-50%);
    border-radius: 2px;
  }
}
.section-title-third {
  font-size: 32px;
  font-weight: 700;
  position: relative;
  padding-left: 26px;
  color: $font-color01;
  margin-bottom: 40px;
  @media screen and (max-width: $medium) {
    font-size: 26px;
    margin-bottom: 16px;
  }
  &::before {
    content: '';
    position: absolute;
    top: 50%;
    left: 0;
    display: block;
    width: 10px;
    height: 1.2em;
    background-color: $key-color01;
    border-radius: 2px;
    transform: translateY(-50%);
  }
}