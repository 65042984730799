/**
 * Estimate
 * ================================================== */
.estimate {
  .steps-img-area {
    text-align: center;
    picture {
      display: block;
      img {
        vertical-align: middle;
      }
    }
    .step-text {
      margin-top: 56px;
      text-align: left;
      font-size: 20px;
      line-height: 2;
      @media screen and (max-width: $medium) {
        margin-top: 24px;
        font-size: 16px;
      }
      + .step-text {
        margin-top: 1em;
      }
      .notice {
        font-size: 16px;
        margin-left: 1em;
        @media screen and (max-width: $medium) {
          font-size: 14px;
          display: inline-block;
          width: 100%;
          margin: 0;
        }
      }
    }
  }
  .sec-form  {
    margin-top: 56px;
    @media screen and (max-width: $medium) {
      margin-top: 24px;
    }
    .form-area {
      display: flex;
      align-items: stretch;
      justify-content: space-between;
      flex-wrap: wrap;
      @media screen and (max-width: $medium) {
        flex-direction: column;
      }
      .form-title,
      .form-content {
        border-top: 1px solid $border-color02;
        padding: 12px 0;
        @media screen and (max-width: $medium) {
          padding: 24px 0;
        }
        &:last-of-type {
          border-bottom: 1px solid $border-color02;
        }
      }
      .form-title {
        width: 200px;
        font-size: 18px;
        font-weight: 700;
        color: $font-color01;
        @media screen and (max-width: $medium) {
          width: 100%;
          line-height: 1;
          padding-bottom: 12px;
        }
        &:last-of-type {
          @media screen and (max-width: $medium) {
            border-bottom: none;
          }
        }
        &.required {
          position: relative;
          &::before {
            content: '必須';
            display: block;
            padding: 4px 12px;
            background-color: $key-color01;
            color: #fff;
            font-size: 12px;
            line-height: 1;
            position: absolute;
            top: calc(12px + .45em);
            right: 0;
            border-radius: 4px;
          }
        }
      }
      .form-content {
        width: calc(100% - 200px);
        margin-left: auto;
        padding-left: 48px;
        font-size: 16px;
        @media screen and (max-width: $medium) {
          width: 100%;
          padding-top: 0;
          padding-left: 0;
          border-top: none;
        }
        label {
          cursor: pointer;
          + label {
            margin-left: 32px;
          }
        }
        input {
          &:not([type="radio"]) {
            display: inline-block;
            width: 100%;
            height: auto;
            border: 1px solid $border-color02;
            padding: 8px;
            border-radius: 4px;
            font-size: 16px;
            line-height: 1;
            box-sizing: border-box;
            &.very-sht {
              max-width: 163px;
            }
            &.sht {
              max-width: 410px;
            }
            &.mid {
              max-width: 640px;
            }
          }
          &[type="radio"] {
            margin-right: 16px;
            vertical-align: middle;
          }
        }
        select {
          border: 1px solid $border-color02;
          padding: 8px;
          box-sizing: border-box;
          font-size: 16px;
          border-radius: 4px;
        }
        textarea {
          width: 100%;
          max-width: 100%;
          border: 1px solid $border-color02;
          border-radius: 4px;
          padding: 8px;
        }
        .post {
          font-size: 18px;
          display: inline-block;
          margin-right: 8px;
          + input {
            @media screen and (max-width: $medium) {
              width: calc(100% - 1.125em - 8px);
            }
          }
        }
        .form-content-text {
          margin-top: 4px;
          display: inline-block;
        }
        .mwform-radio-field {
          + .mwform-radio-field {
            margin-left: 32px;
          }
        }
      }
    }
  }
  .contact-privacy {
    margin-top: 56px;
    background-color: #F5F5F5;
    padding: 24px 32px;
    @media screen and (max-width: $medium) {
      margin-top: 24px;
    }
    .contact-privacy-title {
      font-size: 20px;
      font-weight: 700;
      margin-bottom: 16px;
      @media screen and (max-width: $medium) {
        font-size: 16px;
      }
    }
    .contact-privacy-text {
      font-size: 16px;
      line-height: 2;
      @media screen and (max-width: $medium) {
        font-size: 14px;
      }
    }
    .contac-privacy-agree {
      margin-top: 24px;
      text-align: center;
      font-size: 20px;
      vertical-align: middle;
      text-align: center;
      @media screen and (max-width: $medium) {
        font-size: 16px;
      }
      .contac-privacy-agree-inner {
        display: inline-block;
      }
      label {
        display: flex;
        align-items: center;
        justify-content: center;
        cursor: pointer;
        line-height: 1;
      }
      input {
        &[type="checkbox"] {
          width: 30px;
          height: 30px;
          margin-right: 16px;
          @media screen and (max-width: $medium) {
            width: 18px;
            height: 18px;
            margin-right: 8px;
          }
        }
      }
    }
  }
  .next-area {
    margin-top: 100px;
    display: flex;
    align-items: center;
    justify-content: center;
    @media screen and (max-width: $medium) {
      margin-top: 50px;
    }
    .cmn-btn01 {
      width: 100%;
      max-width: 228px;
      margin: 4px;
    }
  }
  footer {
    margin-top: 180px;
    @media screen and (max-width: $medium) {
      margin-top: 128px;
    }
  }
  .mw_wp_form {
    &.mw_wp_form_input {
      .steps-img-area {
        &.confirm-step {
          display: none;
        }
      }
    }
    &.mw_wp_form_confirm {
      .steps-img-area {
        &.input-step {
          display: none;
        }
      }
    }
    &.mw_wp_form_complete {
      .step-text {
        text-align: center;
        @media screen and (max-width: $medium) {
          text-align: left;
        }
      }
    }
  }
}