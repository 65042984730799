/**
 * Links
 * ================================================== */
:root {
  --boxf_nav_anim_speed: .5s;
}
.detail,
.archive {
  #contents {
    nav.boxf_nav {
      margin-top: 80px;
      @media screen and (max-width: $medium) {
        margin-top: 40px;
      }
      .nav-content {
        display: flex;
        align-items: flex-start;
        justify-content: flex-start;
        flex-wrap: wrap;
        padding: 24px 0;
        position: relative;
        &:last-of-type {
          &::after {
            content: '';
            top: auto;
            bottom: -1px;
          }
        }
        &::before {
          content: '';
        }
        &::before,
        &::after {
          display: block;
          width: 100%;
          height: 2px;
          background-color: $border-color01;
          position: absolute;
          top: -1px;
          left: 50%;
          transform: translateX(-50%);
        }
        .title-area {
          width: 332px;
          padding-left: 16px;
          @media screen and (max-width: $medium) {
            width: 100%;
            margin-bottom: 16px;
            cursor: pointer;
            position: relative;
          }
          &::before,
          &::after {
            @media screen and (max-width: $medium) {
              content: '';
              display: block;
              width: 1em;
              height: 1px;
              background-color: $border-color03;
              position: absolute;
              top: calc(50% - 1px);
              right: 16px;
              transition: all var(--boxf_nav_anim_speed);
            }
          }
          &::after {
            @media screen and (max-width: $medium) {
              transform: rotate(-90deg);
            }
          }
          &.opend {
            &::after {
              @media screen and (max-width: $medium) {
                transform: rotate(0deg);
              }
            }
          }
          .nav-title {
            font-size: 20px;
            font-weight: 700;
            color: $font-color01;
            @media screen and (max-width: $medium) {
              font-size: 18px;
            }
          }
        }
        .links-area {
          width: calc(100% - 332px);
          overflow: hidden;
          @media screen and (max-width: $medium) {
            width: 100%;
            padding: 0 16px;
          }
          ul {
            display: flex;
            align-items: flex-start;
            justify-content: flex-start;
            flex-wrap: wrap;
            margin: -8px -12px;
            li {
              float: none;
              @media screen and (max-width: $medium) {
                width: 100%;
              }
              &:nth-of-type(n) {
                background: transparent;
                margin: 8px 12px;
                a, button {
                  display: flex;
                  align-items: center;
                  justify-content: flex-start;
                  width: 280px;
                  height: 50px;
                  border: 1px solid $key-color01;
                  line-height: 20px;
                  padding: 10px 28px 10px 0;
                  text-align: left;
                  color: $key-color01;
                  text-decoration: none;
                  background-color: #fff;
                  position: relative;
                  cursor: pointer;
                  transition: all .2s;
                  padding-left: 60px;
                  @media screen and (max-width: $medium) {
                    width: 100%;
                  }
                  &:hover {
                    background-color: $key-color01;
                    color: white;
                    &::before {
                      border-color: white transparent transparent transparent;
                    }
                  }
                  &::before {
                    content: '';
                    display: block;
                    width: 0;
                    height: 0;
                    border-style: solid;
                    border-width: 4px 4px 0 4px;
                    border-color: $key-color01 transparent transparent transparent;
                    position: absolute;
                    top: 50%;
                    right: 10px;
                    transform: translateY(-50%);
                    transition: all .2s;
                  }
                  img {
                    border-radius: 99999px;
                    box-shadow: 0 0 4px rgba(0, 0, 0, .1);
                    background-color: #fff;
                    width: 42px;
                    height: 42px;
                    display: block;
                    position: absolute;
                    top: 50%;
                    left: 10px;
                    transform: translateY(-50%);
                  }
                }
              }
            }
          }
        }
      }
    }
  }
}